<template>
    <div>
      <div class="holder">
         <div class="container-fluid">
            <p @click="backBtn()" class="back-text"><span><i class="fa fa-chevron-left"></i></span>Back</p>

            <div class="row">
               <div class="col-md-6">
                  <div class="lock-top-text">
                     <h2>Lock your points</h2>
                     <h6>Secure your points to grow their value and protect them from transfers during the lock period.</h6>
                  </div>

                  <div class="m-auto text-center mt-4" v-if="loading"><Loader/></div>
               </div>

               <div class="col-md-6">
                  <div class="lock-option-holder">
                     <h6>I'm locking</h6>
                     <div class="form-group">
                        <input class="form-control" v-model="points" type="number" name="lockname" id="lock" placeholder="Points to lock?">
                     </div>
                     

                     <div class="mt-5">
                        <label for="">Choose duration</label>

                        <div class="form-check" v-for="(item, index) in lockTypes" :key="index">
                           <input class="form-check-input" type="radio" name="exampleRadios" v-model="option" :id="index" :value="item">
                           <label class="form-check-label" :for="index">
                              {{item}}
                           </label>
                        </div>

                        <div class="form-group" v-if="option === 'Let me choose'">
                           <input v-model="end_date" :min="minDate" type="date" class="form-control input-text" name="date" id="name" placeholder="Choose end date">
                        </div>


                        <!-- <div class="form-check">
                           <input class="form-check-input" type="radio" name="lock" id="points50" value="points50" >
                           <label class="form-check-label" for="points50">
                                 Until i reach 50 points
                           </label>
                        </div>
                        <div class="form-check">
                           <input class="form-check-input" type="radio" name="lock" id="bonus" value="bonus">
                           <label class="form-check-label" for="bonus">
                              Until I qualify for a 20% bonus
                           </label>
                        </div>
                        <div class="form-check">
                           <input class="form-check-input" type="radio" name="lock" id="eoy" value="eoy">
                           <label class="form-check-label" for="eoy">
                              End of this year
                           </label>
                        </div>
                        <div class="form-check">
                           <input class="form-check-input" type="radio" name="lock" id="choose" value="choose">
                           <label class="form-check-label" for="choose">
                              Let me choose
                           </label>
                        </div> -->
                        <hr>
                        <p class="lock-note">Points will be eligible for unlocking after the selected period</p>

                        <div class="form-group lock-btn">
                           <button class="btn" :disabled="loading || locking" @click="lockPoints()">
                              {{ locking ? "Locking points..." : "Lock my points" }}
                           </button>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </div>
    </div>
</template>


<script>
// import transactionPin from "../services/transactionPin";
import Loader from "../components/Loader.vue";
import trip from "../services/trip";

export default {
   name: "SmartLock",
   components: {
    //   VoucherOptions,
    Loader,
  },
   data() {
      return {
         loading: false,
         locking: false,
         lockTypes: [],
         points: "",
         option: "",
         end_date: "",
         serverEndOfYear: "",
         minDate: new Date().toISOString().split("T")[0],
      }
   },

   mounted() {
      this.fetchLockOptions();
   },
   methods: {
      backBtn() {
         this.$router.push({path: `/user/dashboard`});
      },

      fetchLockOptions() {
         this.loading = true;
         trip.lockOptions().then(res => {
            console.log("Options", res)
            this.loading = false;
            this.serverEndOfYear = res.end_of_the_year;
            this.lockTypes = (res.options);
         }).catch((err) => {
            this.loading = false;
            console.log(err);
         });
      },

      async lockPoints() {
         this.locking = true;
        if (this.points == '0') {
            this.locking = false;
            return this.$toast.error("Points can not be 0");
        }
        if (Number(this.points) < 0) {
            this.locking = false;
            
            return this.$toast.error("Points can not be less than 0");
        }
        console.log("Pi=oiny", this.points)
        if (this.points == '') {
            this.locking = false;
            return this.$toast.error("Enter point amount");
         }
        if (this.option == '') {
            this.locking = false;
            return this.$toast.error("Choose lock duration");
        }
        if (this.option == 'Let me choose' && this.end_date == '') {
            this.locking = false;
            return this.$toast.error("Select end date");
        }
         const body = {
            amount: this.points,
            // option: this.option,
            // end_of_the_year: this.end_date,
            end_date: this.end_date
         }
         if (this.option === 'End of the year') {
            this.end_date = "";
            body.end_date = this.serverEndOfYear;
         }
         try {
            await trip.lockSmartPoint(JSON.stringify(body)).then(res => {
               console.log("point locked", res);
               this.locking = false;
               if (res.success) {
                  this.$toast.success("Points locked successfuly");
                  this.$router.push({path: `/user/dashboard`});
                }
               if (res.error) {
                  this.$toast.error(res.error);
                }
            }).catch((err) => {
               this.locking = false;
               console.log(err);
            });
         } finally {
            // console.log("closed")
         }
      },
   },
}
</script>


<style scoped lang="scss">
.holder {
   background: #ffffff;
   padding: 30px 15px;
}

.back-text {
   font-family: Lato;
   font-weight: 600;
   font-size: 16px;
   line-height: 24px;
   color: #535862;
   margin-bottom: 20px !important;
   cursor: pointer;
   span {
      padding-right: 10px;
   }
}

.lock-top-text {
   h2 {
      font-family: Lato;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      color: #2B3352;

   }
   h6 {
      font-family: Lato;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #555C74;
   }
}


.lock-option-holder{
   border: 1px solid #E9EAEB;
   border-radius: 20px;
   padding: 24px;
}

.form-group {
   input {
      border: 1px solid #D5D7DA;
      height: 44px;
      box-shadow: none;
      outline: 0;
      color: #2B3352;
      font-family: Lato;
      font-weight: 400;
      font-size: 16px;

   }
}

.form-check {
    margin-bottom: 15px;
}

.form-check-input {
    width: 18px;
    height: 18px;
}

.form-check-label {
   padding-top: 4px;
   padding-left: 8px;
}

label {
   font-family: Lato;
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   color: #555C74;
   width: fit-content;
   // letter-spacing: 0%;

}
.lock-btn {
   text-align: center;
   margin-top: 30px;
   button {
      background: #0033EA;
      color: #ffffff;
      font-family: Lato;
      font-weight: 400;
      font-size: 18px;
      outline: 0;
      box-shadow: none;
      width: 100%;
      height: 60px;
   }

}

.lock-note {
   font-family: Lato;
   font-weight: 500;
   font-size: 14px;
   line-height: 20px;
   color: #555C74;
   background: #FFFAEB;
   padding: 10px;
   border-radius: 8px;
   width: 100%;
}

@media screen and (max-width: 599px) {
   .holder {
      padding: 30px 0;
     
   }
}
</style>